/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';

html, body { height: 100%; }
body { 
margin: 0; 
font-family: Roboto, "Helvetica Neue", sans-serif; 
}
//--added by alisha

/* Hide scrollbar for the entire HTML document */

/* For Webkit browsers (Chrome, Safari) */
html::-webkit-scrollbar {
  display: none;
}

/* For Internet Explorer and Edge */
html {
  -ms-overflow-style: none;
  /* IE and Edge */
}

/* For Firefox */
html {
  scrollbar-width: none;
  /* Firefox */
}

/* Ensure overflow functionality is maintained */
html,
body {
  overflow: hidden;
  /* Prevent native scrollbar */
  height: 100%;
  width: 100%;
}



//--end
.mat-elevation-z8{
box-shadow: none;
}
.pointer{
    cursor: pointer;
}
.blue-color{
    background-color: #1e88e5;
}
.red-star::after {
    content: '*';
    color: red;
    margin-right: 4px; 
    margin-left: 3px;
  }
.pad-24 {
    padding: 10px 10px 10px 10px;
    
}
.blue-color{
    background-color: #1e88e5 !important;
    color:white !important;
}
// //---Added by Alisha---
// /* Apply this CSS to the specific custom class */
// .custom-drawer-container {
//   /* Hide scrollbar for Webkit browsers (Chrome, Safari) */
//   ::-webkit-scrollbar {
//     display: none;
//   }

//   /* Hide scrollbar for Internet Explorer, Edge */
//   -ms-overflow-style: none;
//   /* IE and Edge */

//   /* Hide scrollbar for Firefox */
//   scrollbar-width: none;
//   /* Firefox */
// }

// /* Maintain overflow functionality */
// .custom-drawer-container .example-sidenav-content {
//   overflow: auto;
//   /* Ensure overflow is maintained */
// }


// //----end---



.active-status {
    background-color: green;
    color: white;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 3px 5px;
    margin-right: 3px;
  }

  .inactive-status {
    background-color: red;
    color: white;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 3px 5px;
    margin-right: 3px;
  }
  .custom-span {
    font-size: large;
    font-weight: 400;
  }

  .special-table th {
    position: sticky; /* Sticky header */
    top: 0; /* Stick to the top */
    z-index: 1; /* Ensure header stays above content */
  }
  

  .subheading{
  font-size: large;
  font-weight: bold;
  }
  .table-scroll{
    overflow-x: auto;
    height: 22.5vh; 
    max-height: 300px;
    overflow-y: scroll; 
    max-width: 100%;
    padding:0 10px 0 10px;
  }
  .table-scrollcamp{
    overflow-x: auto;
    height: 25.6vh; 
    max-height: 26vh; 
    min-height: 25vh;
    overflow-y: scroll; 
    max-width: 100%;
    padding:0 10px 0 10px;
  }
  .table-scrollcamp1{
    overflow-x: auto;
    height: 50vh; 
    overflow-y: scroll; 
    max-height: 309px;
    padding:0 10px 0 10px;

  }
  .banner-scrollcamp1{
    overflow-x: auto;
    height: 20vh; 
    overflow-y: scroll; 
    max-height: 300px;
  }
  

  .multiselect-dropdown{
    .dropdown-btn{
      .selected-item-container{
        .selected-item{
          max-width: none !important;
          margin:1px;
        }
      }
    }

  }
  // .mat-mdc-form-field{
  //   .mat-mdc-floating-label{
  //     .mdc-floating-label{
  //       padding-bottom: 20px;
  //     }
  //   }
  // } 
  .mdc-floating-label{
    padding-bottom: 20px;
  }
  .mat-mdc-form-field-error-wrapper{
    margin: -2px;
  }
  




  

